import React, { useEffect, useState } from "react";
import { Avatar, Badge, Button, Dropdown, Grid, Menu } from "antd";
import {
  BellOutlined,
  CaretRightOutlined,
  ClockCircleOutlined,
  LogoutOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { makeAvatarName } from "../../utils/common";
import { AccountType } from "../../types/user";
import CompanyPicker from "../Shared/CompanyPicker/CompanyPicker";
import MultiBadge from "../Shared/MultiBadge";
import Can from "../Shared/Can";

interface User {
  name: string;
  email: string;
  surname: string;
  image: string | null;
  typeAccount: AccountType;
  tags: any[];
  avatarColor: string;
  avatarBackground: string;
}

interface IUserAppProps {
  user: User;
  logout?: () => void;
  companies: any[];
  panelNotifications: any[];
  panelMessages: any[];
  currentCompany: any;
}

const UserApp = (props: IUserAppProps): JSX.Element => {
  const {
    user,
    logout,
    companies,
    currentCompany,
    panelNotifications,
    panelMessages,
  } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const screens = useBreakpoint();
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!!isMdBreakpoint);
  }, [screens, setIsMobile]);

  const notificationCount = panelNotifications
    ? panelNotifications.reduce(
        (accumulator, currentValue) => accumulator + currentValue.count,
        0
      )
    : 0;

  const messagesCount = panelMessages
    ? panelMessages.reduce(
        (accumulator, currentValue) => accumulator + currentValue.count,
        0
      )
    : 0;

  const avatar = (
    <Avatar
      key={123}
      size={40}
      src={user.image || null}
      className="user-app__avatar"
      style={{
        color: user.avatarColor,
        backgroundColor: user.avatarBackground,
      }}
    >
      {makeAvatarName(false, user.name, user.surname)}
    </Avatar>
  );

  const userDropdown = (
    <Menu className="user-app__user-dropdown user-dropdown">
      <div className="user-dropdown__user-info user-info" key="0">
        {avatar}

        <div className="user-info__container">
          <p className="user-info__name">
            {user.name} {user.surname}
          </p>
          <p className="user-info__email">{user.email}</p>
          <p className="user-info__position">
            {user.tags.map((tag: any, index) => {
              return `${tag.name}${index !== user.tags.length - 1 ? `, ` : ""}`;
            })}
          </p>
        </div>
      </div>
      <Menu.Item key="2" icon={<SettingOutlined />}>
        <Link to="/profil" className="nav-text">
          Edytuj profil
        </Link>
      </Menu.Item>
      <Can type="bop_view">
        <Menu.Item icon={<QuestionCircleOutlined />} className="menu__item">
          <Link to="/bop" className="nav-text">
            Zgłoś bląd
          </Link>
        </Menu.Item>
      </Can>
      <Menu.Item key="3" icon={<LogoutOutlined />} onClick={logout}>
        Wyloguj {AccountType[user.typeAccount]}a
      </Menu.Item>
      <CompanyPicker
        companies={companies}
        currentCompany={currentCompany}
        notifications={panelNotifications}
        messages={panelMessages}
      />
      <div className="user-info__version">
        <small>Wersja aplikacji v0.0.126 22.04</small>
      </div>
    </Menu>
  );

  return (
    <div className="user-app">
      <Dropdown
        overlay={userDropdown}
        placement="bottomLeft"
        arrow
        trigger={["click"]}
        getPopupContainer={(trigger) =>
          trigger.parentNode?.parentNode as HTMLElement
        }
      >
        {isMobile ? (
          <div>
            <MultiBadge
              notifications={notificationCount}
              messages={messagesCount}
              type="icon"
            >
              {avatar}
            </MultiBadge>
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" className="user-notifications__link">
            <MultiBadge
              notifications={notificationCount}
              messages={messagesCount}
              type="icon"
            >
              <UserOutlined style={{ fontSize: "20px" }} />
            </MultiBadge>
          </a>
        )}
      </Dropdown>
    </div>
  );
};

UserApp.defaultProps = {
  logout: undefined,
};

export default UserApp;

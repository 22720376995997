import React, { PropsWithChildren } from "react";
import { connect } from "react-redux";
import { Card, Col, Layout, Row } from "antd";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo_white.png";
import LoginForm from "./LoginForm";
import { signUp } from "../../../actions/auth";
import { AppState } from "../../../reducers";
import { MetaTitle } from "../../Shared/MetaTitle";

interface ILoginProps {
  isRequest: boolean;
  isLogged: boolean;
  signUpAction: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;
}

interface ILocationStateType {
  from: { pathname: string };
}

const Login: React.FC<PropsWithChildren<ILoginProps>> = (
  props: PropsWithChildren<ILoginProps>
): JSX.Element => {
  const { Content } = Layout;
  const location = useLocation<ILocationStateType>();
  const history = useHistory();

  const { signUpAction, isRequest, isLogged } = props;
  const handleSubmit = (
    username: string,
    password: string,
    remember: boolean,
    setStatus: (status: string) => void
  ) => {
    signUpAction(username, password, remember)
      .then(() => {
        const { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.data?.code === 403) {
          return setStatus("Konto jest nieaktywne");
        }

        if (error.status === 401) {
          setStatus("Nieprawidłowe dane logowania");
        }
      });
  };

  if (isLogged) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Layout>
      <MetaTitle title="Logowanie" displayBadge={false} />
      <Content className="auth-login">
        <Row justify="center" gutter={16}>
          <Col span={24} className="auth-login__logo-container">
            <img src={logo} className="auth-login__logo" alt="logo" />
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          className="auth-login__container"
          gutter={[16, 16]}
        >
          <Col
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 2, span: 24 }}
          >
            <Card className="auth-login__card" bordered title="Logowanie">
              <LoginForm handleSubmit={handleSubmit} isRequest={isRequest} />
            </Card>
          </Col>
        </Row>
        <Row
          justify="center"
          className="auth-login__copyrights copyrights"
          gutter={16}
        >
          <Col span={24}>
            <p className="copyrights__text">
              © 2015-2023 jobin.to v0.0.126 22.04
            </p>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isRequest: state.auth.isRequest,
    isLogged: state.auth.isLogged,
  };
};

const mapDispatchToProps = {
  signUpAction: signUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

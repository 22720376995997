import { DateTime, DurationUnits } from "luxon";
import generator from "generate-password";
import { IconType } from "antd/es/notification";
import { notification, Tooltip } from "antd";
import { AxiosError } from "axios";
import { FormikErrors } from "formik";
import { Linkify, Options } from "quill-linkify";
import moment from "moment";

import { Link } from "react-router-dom";
import React from "react";
import { emoji } from "@charkour/react-reactions";
import { CategoriesConfig } from "emoji-picker-react/dist/config/categoryConfig";
import { CategoryConfig } from "emoji-picker-react/src/config/categoryConfig";
import Icon, { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { INewUserFormValues } from "../types/user";
import { INewSplitterFormValues } from "../types/ticket-group";

export const makeAvatarName = (
  deleted = false,
  name: string,
  surname?: string
): string => {
  if (deleted && surname) {
    return `${surname.substr(0, 4)}`.toUpperCase();
  }
  if (!name) {
    return "";
  }
  if (surname) {
    return `${name.substr(0, 1)}${surname.substr(0, 1)}`.toUpperCase();
  }
  return `${name.substr(0, 3)}`.toUpperCase();
};

export const timeAgoFromTimestamp = (timestamp: number) => {
  if (!timestamp) return "Nigdy";

  const units: DurationUnits = [
    "year",
    "month",
    "week",
    "day",
    "hour",
    "minute",
    "second",
  ];

  const dateTime = DateTime.fromMillis(timestamp);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((u) => diff.get(u) !== 0) || "second";

  const relativeFormatter = new Intl.RelativeTimeFormat("pl", {
    numeric: "auto",
  });
  return relativeFormatter.format(
    Math.trunc(diff.as(unit)),
    unit as
      | "year"
      | "years"
      | "quarter"
      | "quarters"
      | "month"
      | "months"
      | "week"
      | "weeks"
      | "day"
      | "days"
      | "hour"
      | "hours"
      | "minute"
      | "minutes"
      | "second"
      | "seconds"
  );
};

export const timeElapsedFromTimestamp = (timestamp: number) => {
  const date = DateTime.fromMillis(timestamp, { locale: "pl" });
  const now = DateTime.now();
  const diff = now.diff(date, ["days", "hours", "minutes", "seconds"], {});

  if (diff.days) {
    return `${diff.days}d ${diff.hours}h ${diff.minutes}m ${Math.round(
      diff.seconds
    )}s`;
  }
  if (diff.hours) {
    return `${diff.hours}h ${diff.minutes}m ${Math.round(diff.seconds)}s`;
  }
  return `${diff.minutes}m ${Math.round(diff.seconds)}s`;
};

export const timestampToDate = (timestamp: number, format: string): string => {
  return DateTime.fromMillis(timestamp, { locale: "pl" }).toFormat(format);
};

export const formatSecondsToFootballMatch = (seconds: number): number => {
  return Math.floor(seconds / 60 / 90);
};

export const formatSeconds = (seconds: number, days = false): string => {
  const number = Number(seconds);

  if (number === 0) return "0h 0m 0s";

  if (days) {
    const d = Math.floor(number / (3600 * 24));
    const h = Math.floor((number / 3600) % 24);
    let m = Math.floor((number % 3600) / 60);
    m = d > 0 ? m % 60 : m;
    const s = Math.floor((number % 3600) % 60);

    const dDisplay = d > 0 ? `${d}d ` : "";
    const hDisplay = h > 0 ? `${h}h ` : "";
    const mDisplay = m > 0 ? `${m}m ` : "";
    const sDisplay = s > 0 ? `${s}s ` : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  const h = Math.floor(number / 3600);
  const m = Math.floor((number % 3600) / 60);
  const s = Math.floor((number % 3600) % 60);
  const hDisplay = h > 0 ? `${h}h ` : "";
  const mDisplay = m > 0 ? `${m}m ` : "";
  const sDisplay = s > 0 ? `${s}s ` : "";
  return hDisplay + mDisplay + sDisplay;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const stringToColour = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};

export const formatMoney = (value: number | string): string => {
  if (typeof value === "string") {
    return `${parseFloat(value).toFixed(2)} zł`;
  }

  return `${value.toFixed(2)} zł`;
};

export const generateRandomString = (): string => {
  return generator.generate({ length: 8 });
};

export const openNotificationWithIcon = (
  type: IconType,
  message: string,
  description?: string
) => {
  notification.config({
    maxCount: 1,
    duration: 2,
    top: 68,
  });
  notification[type]({
    message,
    description,
  });
};

export const openNetworkNotification = (
  type: IconType,
  message: string,
  isOnline: boolean,
  description?: string
) => {
  notification.config({
    maxCount: 1,
    duration: isOnline ? 2 : 0,
    top: 68,
  });
  notification[type]({
    message,
    description,
    placement: "topLeft",
  });
};

export const transformToFormikError = (
  responseError: AxiosError<any>
): FormikErrors<INewUserFormValues | any> => {
  interface IErrorsInterface {
    errors: [] | null;
  }
  const errorsArray: IErrorsInterface =
    responseError.response?.data?.errors || {};

  const formikResponse: FormikErrors<
    INewUserFormValues | INewSplitterFormValues | any
  > = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(errorsArray)) {
    if (Array.isArray(value)) {
      if (value.length) {
        formikResponse[
          key as keyof (INewUserFormValues | INewSplitterFormValues)
        ] = value.length ? value.join(",") : "";
      }
    }
  }
  return formikResponse;
};

export const mergeByProperty = (target: any[], source: any, prop: string) => {
  return source.forEach((sourceElement: any) => {
    const targetElements = target.find((targetElement: any) => {
      return sourceElement[prop] === targetElement[prop];
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    targetElements
      ? Object.assign(targetElements, sourceElement)
      : target.push(sourceElement);
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const groupBy = <T extends object, U extends keyof T>(
  xs: T[] = [],
  key: U
): { [key: string]: T[] } => {
  return xs.reduce((rv: any, x: T) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const defaultInitializer = (index: number) => index;

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index));
}

export const linkifyOptions: Options = {
  /* custom (regexp or true) or (false or undefined) */
  url: true,
  mail: true, // Use default regexp
  phoneNumber: false, // Disable text auto link
};

export const defaultEditorModulesWithMedia = {
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "code-block",
      "image",
    ],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  magicUrl: true,
};

export const defaultEditorModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  magicUrl: true,
};
export const defaultEditorFormats = [
  "bold",
  "color",
  "code",
  "italic",
  "link",
  "strike",
  "underline",
  "blockquote",
  "list",
  "code-block",
  "image",
  "video",
];

export const noUploaderEditorModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  linkify: linkifyOptions,
  uploader: {
    handler: () => {},
  },
  clipboard: {
    matchVisual: false,
  },
};

export const placeCaretAtEnd = (el: HTMLElement) => {
  if (!el) return;
  el.focus();
  // eslint-disable-next-line no-param-reassign
  el.scrollTop = el.scrollHeight;
  if (
    typeof window.getSelection !== "undefined" &&
    typeof document.createRange !== "undefined"
  ) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  }
};

export const isImageFileType = (type: string) => {
  return (
    type === "image/jpeg" ||
    type === "image/png" ||
    type === "image/webp" ||
    type === "image/gif" ||
    type === "image\\/jpeg"
  );
};

export const isAudioFileType = (type: string) => {
  return (
    type === "audio/mpeg" ||
    type === "audio/wav" ||
    type === "audio/ogg" ||
    type === "audio/aac" ||
    type === "audio/webm" ||
    type === "video/webm" ||
    type === "application/octet-stream" ||
    // Add more audio types as needed
    false
  );
};
export const isImageGif = (type: string) => {
  return type === "image/gif";
};

export const isTimeDiffMoreThan = (
  time1: number,
  time2: number,
  maxDiff: number,
  unit: moment.unitOfTime.Diff
) => {
  const a = moment(time1 * 1000);
  const b = moment(time2 * 1000);
  const diff = a.diff(b, unit);
  return Math.abs(diff) > maxDiff;
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // if (/Safari/i.test(userAgent)) {
  //   return "iOS";
  // }

  return "unknown";
};

export const isInstalledIOS = () => {
  const UA = navigator.userAgent || navigator.vendor;

  const IOS = UA.match(/iPhone|iPad|iPod/);

  const standalone = window.matchMedia("(display-mode: standalone)").matches;

  const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)));

  return INSTALLED;
};
// const isPWA = () => {
//   const UA = navigator.userAgent;
//
//   const IOS = UA.match(/iPhone|iPad|iPod/);
//   const ANDROID = UA.match(/Android/);
//
//   export const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : 'unknown';
//
//   const standalone = window.matchMedia('(display-mode: standalone)').matches;
//
//   export const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)));
// }

export const reactionsToNode = (
  reaction: string,
  count: number
): JSX.Element => {
  switch (reaction) {
    case "satisfaction":
      return (
        <div>
          {count > 1 && (
            <small className="chat__reactions-count">{count}</small>
          )}
          👍
        </div>
      );
    case "love":
      return (
        <div style={{ top: 1, fontSize: 12 }}>
          {count > 1 && (
            <small className="chat__reactions-count">{count}</small>
          )}
          ❤️
        </div>
      );
    case "happy":
      return (
        <div>
          {count > 1 && (
            <small className="chat__reactions-count">{count}</small>
          )}
          😆
        </div>
      );
    case "surprise":
      return (
        <div>
          {count > 1 && (
            <small className="chat__reactions-count">{count}</small>
          )}
          😮
        </div>
      );
    case "sad":
      return (
        <div>
          {count > 1 && (
            <small className="chat__reactions-count">{count}</small>
          )}
          😢
        </div>
      );
    default:
      return (
        <div>
          {count > 1 && (
            <small className="chat__reactions-count">{count}</small>
          )}
          😡
        </div>
      );
  }
};

export const defaultReactions = [
  {
    node: <div>👍</div>,
    label: "ok",
    key: "1f44d",
  },
  {
    node: <div>❤️</div>,
    label: "serduszko",
    key: "2764-fe0f",
  },
  {
    node: <div>😆</div>,
    label: "haha",
    key: "1f606",
  },
  {
    node: <div>😮</div>,
    label: "wow",
    key: "1f62e",
  },
  { node: <div>😢</div>, label: "smuteczek", key: "1f625" },
  {
    node: <div>😡</div>,
    label: "zły",
    key: "1f621",
  },
  {
    node: (
      <div>
        <PlusOutlined />
      </div>
    ),
    label: "więcej",
    key: "more",
  },
];

export const emojisCategories: any = [
  {
    category: "suggested",
    name: "Najczęściej używane",
  },
  {
    category: "smileys_people",
    name: "Buźki",
  },
  {
    category: "animals_nature",
    name: "Zwierzęta i natura",
  },
  {
    category: "food_drink",
    name: "Jedzonko",
  },
  {
    category: "travel_places",
    name: "Podróże",
  },
  {
    category: "activities",
    name: "Sport",
  },
  {
    category: "objects",
    name: "Obiekty",
  },
  {
    category: "symbols",
    name: "Symbole",
  },
  {
    category: "flags",
    name: "Flagi",
  },
];

export const renderLastActivity = (lastActivity: any) => {
  switch (lastActivity?.type) {
    case "LOGIN":
      return <span>Uruchomienie panelu</span>;
    case "VIEW_DASHBOARD":
      return <span>Przegląd kokpitu</span>;
    case "VIEW_HOME":
      return <span>Przegląd strony głównej</span>;
    case "VIEW_CALENDAR":
      return <span>Przegląd kalendarza</span>;
    case "VIEW_ASSIGNED":
      return <span>Przegląd przydzielonych</span>;
    case "VIEW_ARCHIVE":
      return <span>Przegląd archiwum</span>;
    case "VIEW_CHAT":
      return <span>Przegląd czatu</span>;
    case "VIEW_NOTIFICATIONS":
      return <span>Przegląd powiadomień</span>;
    case "VIEW_TICKET_GROUPS_LIST":
      return <span>Przegląd listy działów</span>;
    case "VIEW_USERS":
      return <span>Przegląd listy użytkowników</span>;
    case "VIEW_TICKET":
      return (
        <span>
          <Tooltip title={lastActivity?.ticket?.title}>
            <Link
              to={`/group/${lastActivity?.ticket?.group?.id}/${lastActivity?.ticket?.id}`}
            >
              Przegląd wątku #{lastActivity?.ticket?.displayId}
            </Link>
          </Tooltip>
        </span>
      );
    case "VIEW_TICKET_GROUP":
      return (
        <span>
          <Tooltip title={lastActivity?.ticketGroup?.name}>
            <Link to={`/group/${lastActivity?.ticketGroup?.id}`}>
              Przegląd działu #{lastActivity?.ticketGroup?.displayId}
            </Link>
          </Tooltip>
        </span>
      );
    default:
      return null;
  }
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Input, Space } from "antd";

import _ from "lodash";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SearchInput from "./SearchInput";
import SearchService from "../../../services/search-service";
import TicketsService from "../../../services/tickets-service";
import useOutsideClick from "../../../hooks/useOutsideClick";
import SearchBox from "./SearchBox";
import { openNotificationWithIcon } from "../../../utils/common";

interface ISearchProps {
  isMobile: boolean;
  isToggled: boolean;
  toggleSearch: (value?: boolean) => void;
  lastVisited: any;
  lastVisitedLoading: boolean;
  getLastVisitedAction: () => Promise<any>;
}
const Search = (props: ISearchProps) => {
  const abortController = useRef(new AbortController());
  const {
    isMobile,
    isToggled,
    toggleSearch,
    lastVisited,
    lastVisitedLoading,
    getLastVisitedAction,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [results, setResults] = useState<any>({ tickets: [], groups: [] });
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const history = useHistory();

  const closeSearch = () => {
    setOpen(false);
    toggleSearch(false);
    setSearchQuery("");
  };

  useOutsideClick(wrapperRef, () => {
    closeSearch();
  });

  const debounceSearch = useMemo(
    () =>
      _.debounce((query: string) => {
        abortController.current.abort();
        abortController.current = new AbortController();
        setSearching(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        SearchService.search(query, abortController.current)
          .then((response) => {
            setResultsVisible(true);
            setResults(response.data);
          })
          .finally(() => {
            setSearching(false);
          });
      }, 300),
    []
  );

  const handleSearch = useCallback(
    (query: string) => {
      if (!query) {
        abortController.current.abort();
        debounceSearch.cancel();
        return setResultsVisible(false);
      }

      return debounceSearch(query);
    },
    [debounceSearch]
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const withoutHash = searchQuery.replace("#", "");

      if (Number(withoutHash)) {
        const id = Math.abs(parseInt(withoutHash, 10));
        closeSearch();
        TicketsService.getSearchTicket(id)
          .then((response) => {
            const ticket = response.data;
            if (ticket?.id) {
              history.replace(`/group/${ticket.group.id}/${ticket.id}`);
            }
          })
          .catch((err) => {
            if (err.response?.status === 403) {
              openNotificationWithIcon(
                "error",
                "Nie posiadasz uprawnień do wyświetlenia tego wątku."
              );
            }
          });
      }
    }
  };

  useEffect(() => {
    handleSearch(searchQuery);
  }, [handleSearch, searchQuery]);

  useEffect(() => {
    if ((!isMobile && open) || (isMobile && isToggled)) {
      getLastVisitedAction();
    }
  }, [isToggled, isMobile, open, getLastVisitedAction]);

  return (
    <div ref={wrapperRef} className="search__wrapper">
      <Space className="search__container" direction="vertical">
        <SearchInput
          value={searchQuery}
          isMobile={isMobile}
          isToggled={isToggled}
          isOpen={open}
          isSearching={searching}
          toggleSearch={toggleSearch}
          setOpen={setOpen}
          onChange={setSearchQuery}
          handleKeyPress={(event) => handleKeyPress(event)}
        />
        <SearchBox
          open={(!isMobile && open) || (isMobile && isToggled)}
          lastVisited={lastVisited}
          lastVisitedLoading={lastVisitedLoading}
          results={results}
          resultsVisible={!!searchQuery.length && resultsVisible}
          onRowClick={closeSearch}
        />
      </Space>
    </div>
  );
};

export default Search;
